import AiImageEditorImage from "../assets/images/svgs/AI Image Editor.svg";
import CampaignImage from "../assets/images/svgs/Campaign - future.svg";
import GenerateImage from "../assets/images/svgs/Generate Images.svg";
import GarnierLogo from "../assets/images/svgs/Logo Garnier.svg";
import LorealMyAssetsImage from "../assets/images/svgs/LorealAssets.svg";
import LorealCampaignImage from "../assets/images/svgs/LorealCampaign.svg";
import LorealAiImageEditorImage from "../assets/images/svgs/LorealEditor.svg";
import LorealGenerateImage from "../assets/images/svgs/LorealGenerate.svg";
import LorealLogo from "../assets/images/svgs/LorealLogo.svg";
import MyAssetsImage from "../assets/images/svgs/My Assets.svg";
import Logo from "../assets/images/svgs/logo.svg";
import { isLoreal } from "../utils";

export const PUBLICIS_ORG_ID = "81ae0fe2-72e8-4554-884a-70fa085d23c3";
export const PAMPERS_ORG_ID = "dbd9e219-9075-4ba1-b60d-ff7a01c90286";
export const PURINA_ORG_ID = "bdd9e219-9075-4ba1-b60d-ff7a01c90287";
export const PUBLICIS_LE_PONT_UNILEVER_ORG_ID = "74b30f4d-99af-4f94-a742-4f3eb2325065";
export const PUBLICIS_LOREAL_ORG_ID = "ee08a8bd-2fbe-4d4f-9915-b36f6a668f34";
export const PUBLICIS_LOREAL_GARNIER_ORG_ID = "e025da1b-21ff-4508-ad62-ca635b3b8f33";

export const DEFAULT_ORG = isLoreal() ? PUBLICIS_LOREAL_ORG_ID : PUBLICIS_ORG_ID;

const LOREAL_ORGS = {
	[PUBLICIS_LOREAL_ORG_ID as string]: {
		name: "Publicis L’Oreal",
		hubTitle: "publicisLorealAiHub",
		logo: LorealLogo,
		homeImages: [LorealGenerateImage, LorealAiImageEditorImage, LorealMyAssetsImage, LorealCampaignImage],
		iframes: {
			IMAGE_EDITOR_IFRAME_ID: "PUBLICIS-LOREAL-1",
			GENERATE_IFRAME_ID: "PUBLICIS-LOREAL-0",
			ASSETS_IFRAME_ID: "PUBLICIS-LOREAL-2",
			CAMPAIGN_IFRAME_ID: "PUBLICIS-LOREAL-3",
		},
	},
	[PUBLICIS_LOREAL_GARNIER_ORG_ID as string]: {
		name: "Publicis L’Oreal Garnier",
		hubTitle: "publicisLorealAiHub",
		logo: GarnierLogo,
		homeImages: [LorealGenerateImage, LorealAiImageEditorImage, LorealMyAssetsImage, LorealCampaignImage],
		iframes: {
			IMAGE_EDITOR_IFRAME_ID: "PUBLICIS-LOREAL-GARNIER-1",
			GENERATE_IFRAME_ID: "PUBLICIS-LOREAL-GARNIER-0",
			ASSETS_IFRAME_ID: "PUBLICIS-LOREAL-GARNIER-2",
			CAMPAIGN_IFRAME_ID: "PUBLICIS-LOREAL-GARNIER-3",
		},
	},
};

export const ORG_CONSTANTS = isLoreal()
	? LOREAL_ORGS
	: {
			[PUBLICIS_ORG_ID as string]: {
				name: "Publicis",
				hubTitle: "publicisAiHub",
				logo: Logo,
				homeImages: [GenerateImage, AiImageEditorImage, MyAssetsImage, CampaignImage],
				iframes: {
					IMAGE_EDITOR_IFRAME_ID: "Publicis-1",
					GENERATE_IFRAME_ID: "Publicis-0",
					ASSETS_IFRAME_ID: "Publicis-2",
					CAMPAIGN_IFRAME_ID: "Publicis-3",
				},
			},
			[PAMPERS_ORG_ID as string]: {
				name: "Pampers",
				hubTitle: "publicisAiHub",
				logo: Logo,
				homeImages: [GenerateImage, AiImageEditorImage, MyAssetsImage, CampaignImage],
				iframes: {
					IMAGE_EDITOR_IFRAME_ID: "Pampers-1",
					GENERATE_IFRAME_ID: "Pampers-0",
					ASSETS_IFRAME_ID: "Pampers-2",
					CAMPAIGN_IFRAME_ID: "Pampers-3",
				},
			},
			[PURINA_ORG_ID as string]: {
				name: "Purina",
				hubTitle: "publicisAiHub",
				logo: Logo,
				homeImages: [GenerateImage, AiImageEditorImage, MyAssetsImage, CampaignImage],
				iframes: {
					IMAGE_EDITOR_IFRAME_ID: "Purina-1",
					GENERATE_IFRAME_ID: "Purina-0",
					ASSETS_IFRAME_ID: "Purina-2",
					CAMPAIGN_IFRAME_ID: "Purina-3",
				},
			},
			[PUBLICIS_LE_PONT_UNILEVER_ORG_ID as string]: {
				name: "Publicis Le Pont Unilever",
				hubTitle: "publicisAiHub",
				logo: Logo,
				homeImages: [GenerateImage, AiImageEditorImage, MyAssetsImage, CampaignImage],
				iframes: {
					IMAGE_EDITOR_IFRAME_ID: "PUBLICIS-LE-PONT-UNILEVER-1",
					GENERATE_IFRAME_ID: "PUBLICIS-LE-PONT-UNILEVER-0",
					ASSETS_IFRAME_ID: "PUBLICIS-LE-PONT-UNILEVER-2",
					CAMPAIGN_IFRAME_ID: "PUBLICIS-LE-PONT-UNILEVER-3",
				},
			},
			...LOREAL_ORGS,
	  };
