import { Box } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useLocation } from "react-router-dom";
import BriaIframe from "../../components/common/BriaIframe/BriaIframe";
import { PagesEnum } from "../../constants/IframeConstants";
import { getPublicisSelectedOrganization, isLoreal } from "../../utils";
import styles from "./ImageEditor.module.scss";

const ImageEditor = () => {
	const location = useLocation();

	return (
		<Box className={styles.container}>
			<BriaIframe
				iframeId={getPublicisSelectedOrganization().iframes.IMAGE_EDITOR_IFRAME_ID}
				target={PagesEnum.Playground}
				vhash={location.state?.vhash ?? (isLoreal() ? "f231c3cb1c6ec2b1" : "8679a552aa79cc96")}
				initUpload={location.state?.initUpload ?? true}
			/>
		</Box>
	);
};

export default observer(ImageEditor);
