import { observer } from "mobx-react-lite";
import { Navigate, Outlet } from "react-router-dom";
import { LoadingPlaceholder } from "../../components/common/LoadingPlaceholder/LoadingPlaceholder.tsx";
import RouterConstants from "../../constants/RouterConstants.ts";
import { useAppStore } from "../../hooks/useStores.tsx";
import Header from "../Header/Header";
import styles from "./AppLayout.module.scss";

type LayoutProps = {
	showPageDropdown?: boolean;
};

const Layout = ({ showPageDropdown }: LayoutProps) => {
	const { authStore } = useAppStore();

	const getContent = () => {
		if (authStore.userOrganizations.length > 0 && location.pathname === RouterConstants.NOT_AUTHORIZED.path) {
			return <Navigate to={RouterConstants.HOME.path} />;
		}
		return (
			<>
				<Header showPageDropdown={showPageDropdown} />
				<div className={styles.sidePagesContainer}>
					<Outlet />
				</div>
			</>
		);
	};

	return (
		<LoadingPlaceholder className={styles.loaderStyle} isLoading={authStore.isLoading}>
			{getContent()}
		</LoadingPlaceholder>
	);
};

export default observer(Layout);
