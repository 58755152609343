import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import {
	Box,
	CircularProgress,
	FormControl,
	InputBase,
	MenuItem,
	Select,
	SelectProps,
	styled,
	Typography,
} from "@mui/material";
import { observer } from "mobx-react-lite";
import styles from "./BriaDropDown.module.scss";

export type DropDownItem = {
	key: string;
	value: string;
	icon?: any;
};

export type BriaDropDownProps<T> = {
	value?: string;
	loading?: boolean;
	items: DropDownItem[];
	height?: string;
	menuMaxHeight?: string;
	width?: string;
	className?: string;
	disabled?: boolean;
	hideArrowIcon?: boolean;
	border?: string;
	icon?: any;
} & SelectProps<T>;

const BriaDropdown = <T,>({
	value,
	loading,
	items,
	width,
	height,
	menuMaxHeight,
	className,
	disabled,
	hideArrowIcon,
	border,
	icon,
	...rest
}: BriaDropDownProps<T>) => {
	const selectedItems = items.find((item) => item.value === value);
	return (
		<StyledFormControl className={className} width={width} height={height}>
			<StyledSelect
				displayEmpty={!!rest.placeholder}
				value={value}
				IconComponent={loading ? DropDownLoading : ExpandMoreRoundedIcon}
				hideArrowIcon={hideArrowIcon || disabled}
				disabled={disabled}
				renderValue={() =>
					selectedItems ? (
						<>
							<Box className={styles.inputStyle}>
								{icon}
								<Typography className={styles.lableStyle}>{selectedItems.key}</Typography>
							</Box>
						</>
					) : (
						rest.placeholder
					)
				}
				MenuProps={{
					style: {
						marginTop: 4,
						maxHeight: menuMaxHeight,
					},
				}}
				input={border ? <SelectInputBase border={border} height={height} /> : undefined}
				{...rest}
			>
				{loading && <StyledMenuItem disabled>Loading..</StyledMenuItem>}
				{items.map((item) => (
					<StyledMenuItem key={item.key} value={item.value}>
						{item.icon ? (
							<Box className={styles.itemConatiner}>
								{item.icon}
								{item.key}
							</Box>
						) : (
							item.key
						)}
					</StyledMenuItem>
				))}
			</StyledSelect>
		</StyledFormControl>
	);
};

export default observer(BriaDropdown);

const StyledFormControl = styled(FormControl)(({ width, height }: { width?: string; height?: string }) => ({
	width: width ?? "auto",
	"& .MuiInputBase-root": {
		height: height ?? "40px",
		minWidth: "100px",
		fontSize: "14px",
		fontWeight: "400",
		justifyContent: "center",
		"&:hover": {
			background: "#E7E7E7",
			borderRadius: "8px",
		},
	},
}));

const SelectInputBase = styled(InputBase)(({ border, height }: { border?: string; height?: string }) => ({
	border: border ? border : "initial",
	"& > div": {
		padding: "16.5px 14px",
		height: height,
	},
}));

const StyledSelect = styled(Select<any>, { shouldForwardProp: (props) => props != "hideArrowIcon" })(
	({ hideArrowIcon }: { hideArrowIcon?: boolean }) => ({
		width: "auto",
		"&.Mui-focused": {
			".MuiSvgIcon-root": {
				fill: "#495057",
			},
		},
		".MuiSelect-icon": {
			display: hideArrowIcon ? "none" : "block",
		},
	}),
);

const StyledMenuItem = styled(MenuItem)({
	fontWeight: 500,
	paddingTop: 8,
	paddingBottom: 8,
	fontSize: "14px",
	color: "#5B5B5B",
	"&.Mui-selected": {
		color: "#1A0638",
		fontWeight: "bold",
	},
});

const DropDownLoading = styled(CircularProgress)(({ theme }) => ({
	color: `${theme.palette.primary.dark} !important`,
	width: "1em !important",
	height: "1em !important",
	right: "13px !important",
}));
