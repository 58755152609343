import InputAdornment from "@mui/material/InputAdornment";
import TextField, { TextFieldProps } from "@mui/material/TextField";
import React from "react";
import { useTranslation } from "react-i18next";
import SearchIcon from "../../../assets/images/svgs/SearchIcon.svg";
import styles from "./BriaInput.module.scss";

interface IProps {
	type?: string;
	optional?: boolean;
	height?: string;
	alignment?: string;
	searchField?: boolean;
}

const BriaInput: React.FC<IProps & TextFieldProps> = ({
	searchField,
	alignment,
	type,
	optional,
	height,
	onChange,
	...rest
}) => {
	const { t } = useTranslation();
	const [hasInput, setHasInput] = React.useState<boolean>(false);

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setHasInput(event.target.value.trim() !== "");
		if (onChange) {
			onChange(event);
		}
	};

	return (
		<TextField
			variant="outlined"
			type={type}
			required={!optional}
			onChange={handleChange}
			InputProps={{
				style: {
					height,
					alignItems: alignment,
				},
				startAdornment: searchField && <img className={styles.searchIconStyle} src={SearchIcon} />,
				endAdornment:
					optional === true ? (
						!hasInput && <InputAdornment position="end">{t("optional")}</InputAdornment>
					) : (
						<></>
					),
			}}
			{...rest}
		/>
	);
};

export default BriaInput;
