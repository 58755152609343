export enum IframePostMessageTypes {
	InitialLoadComplete = "Bria_InitialLoadComplete",
	GalleryImageOpen = "Bria_GalleryImageOpen",
	GalleryOpen = "Bria_GalleryOpen",
	AssetsOpen = "Bria_AssetsOpen",
	CampaignOpen = "Bria_CampaignOpen",
	Auth = "Bria_Auth",
	AuthInit = "Bria_AuthInit",
}

export enum PagesEnum {
	Gallery = "gallery",
	Playground = "playground",
	Campaign = "campaign",
	Assets = "assets",
}
