import { Box, Grid, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { HomeCardComponent } from "../../components/Home/HomeCardComponent";
import { LoadingPlaceholder } from "../../components/common/LoadingPlaceholder/LoadingPlaceholder";
import RouterConstants from "../../constants/RouterConstants";
import { HomeCard } from "../../models/homeCard.ts";
import { getPublicisSelectedOrganization } from "../../utils";
import styles from "./Home.module.scss";

const Home = () => {
	const { t: globalT } = useTranslation("translation");
	const { t } = useTranslation("translation", { keyPrefix: "homePage" });
	const navigate = useNavigate();
	const [numberOfLoadedImages, setNumberOfLoadedImages] = useState(0);

	const cardsList: HomeCard[] = [
		{
			title: globalT("generateImages"),
			description: t("generateImagesDesc"),
			image: getPublicisSelectedOrganization().homeImages[0],
			onClick: () => {
				navigate(RouterConstants.GENERATE_IMAGE.path);
			},
		},
		{
			title: globalT("aiImageEditor"),
			description: t("aiImageEditorDesc"),
			image: getPublicisSelectedOrganization().homeImages[1],
			onClick: () => {
				navigate(RouterConstants.IMAGE_EDITOR.path);
			},
		},
		{
			title: globalT("myAssets"),
			description: t("myAssetsDesc"),
			image: getPublicisSelectedOrganization().homeImages[2],
			onClick: () => {
				navigate(RouterConstants.MY_ASSETS.path);
			},
		},
		{
			title: globalT("campaign"),
			description: t("campaignDesc"),
			image: getPublicisSelectedOrganization().homeImages[3],
			onClick: () => {
				navigate(RouterConstants.CAMPAIGN.path);
			},
		},
	];

	return (
		<LoadingPlaceholder
			isLoading={numberOfLoadedImages < cardsList.length}
			alwaysRenderChildren={true}
			className={styles.loaderStyle}
		>
			<Box className={styles.container}>
				<Box className={styles.titleContainer}>
					<Typography className={styles.title}>
						{globalT(getPublicisSelectedOrganization().hubTitle)}
					</Typography>
				</Box>
				<Box className={styles.cardsContainer}>
					<Grid container spacing={4}>
						{cardsList.map((card: HomeCard) => (
							<HomeCardComponent
								title={card.title}
								description={card.description}
								image={card.image}
								onClick={card.onClick}
								onImageLoad={() => {
									setNumberOfLoadedImages((prevCount) => prevCount + 1);
								}}
							/>
						))}
					</Grid>
				</Box>
			</Box>
		</LoadingPlaceholder>
	);
};

export default observer(Home);
