import { matchPath } from "react-router-dom";
import { DEFAULT_ORG, ORG_CONSTANTS } from "../constants/OrgConstants.ts";
import RouterConstants from "../constants/RouterConstants";
import { getSelectedOrganization } from "../helpers/localStorage.ts";

export function capitalizeFirstLetter(text: string) {
	return text ? text.charAt(0).toUpperCase() + text.slice(1) : text;
}

export const getCurrentRoute = () => {
	const currentRoute = Object.entries(RouterConstants).find((route) =>
		matchPath(
			{
				path: route[1].path,
				exact: route[1].exact,
				strict: route[1].strict,
			},
			window.location.pathname,
		),
	);
	return currentRoute?.[1];
};

export const isLoreal = () => {
	return (
		window.location.hostname === "loreal.publicis.bria.ai" ||
		window.location.hostname === "staging-loreal.publicis.bria.ai"
	);
};

export const getPublicisSelectedOrganization = (selectedOrg = getSelectedOrganization()) => {
	return ORG_CONSTANTS[selectedOrg?.organization.uid ?? DEFAULT_ORG] ?? ORG_CONSTANTS[DEFAULT_ORG];
};
