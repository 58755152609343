import { SelectChangeEvent } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { getSelectedOrganization, setSelectedOrganization } from "../../../helpers/localStorage";
import { useAppStore } from "../../../hooks/useStores";
import { UserOrganization } from "../../../models/organization";
import { getPublicisSelectedOrganization } from "../../../utils";
import BriaDropdown, { BriaDropDownProps } from "../BriaDropDown/BriaDropDown";

type OrganizationDropdownProps = {
	reload?: boolean;
} & Partial<BriaDropDownProps<string>>;

const OrganizationsDropdown = ({ reload = true, ...rest }: OrganizationDropdownProps) => {
	const { authStore } = useAppStore();
	const [value, setValue] = useState(getSelectedOrganization()?.organization.uid);
	const [filteredOrgs, setFilteredOrgs] = useState<UserOrganization[]>(authStore.userOrganizations);

	useEffect(() => {
		setFilteredOrgs(authStore.userOrganizations);
	}, [authStore.userOrganizations]);

	const changeHandler = async (event: SelectChangeEvent<string>) => {
		const selOrg = filteredOrgs.find((org) => org.organization.uid === event.target.value);
		if (selOrg) {
			setValue(selOrg.organization.uid);
			setSelectedOrganization(selOrg);
			reload && window.location.reload();
		}
	};

	return (
		<BriaDropdown
			value={value}
			items={filteredOrgs.map((org) => {
				return { key: getPublicisSelectedOrganization(org).name, value: org.organization.uid };
			})}
			onChange={changeHandler}
			border={"none"}
			{...rest}
		/>
	);
};

export default observer(OrganizationsDropdown);
