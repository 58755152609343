import { Box } from "@mui/material";
import { observer } from "mobx-react-lite";
import BriaIframe from "../../components/common/BriaIframe/BriaIframe";
import { PagesEnum } from "../../constants/IframeConstants";
import { getPublicisSelectedOrganization } from "../../utils";
import styles from "./MyAssets.module.scss";

const MyAssets = () => {
	return (
		<Box className={styles.container}>
			<BriaIframe
				iframeId={getPublicisSelectedOrganization().iframes.ASSETS_IFRAME_ID}
				target={PagesEnum.Assets}
			/>
		</Box>
	);
};

export default observer(MyAssets);
