import { Box, Typography } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import styles from "./NotAuthorized.module.scss";

const NotAuthorized = () => {
	const { t } = useTranslation("translation", { keyPrefix: "notAuthorized" });
	return (
		<Box className={styles.container}>
			<Typography variant={"h4"}>{t("message")}</Typography>
		</Box>
	);
};

export default observer(NotAuthorized);
