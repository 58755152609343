export default class RouterConstants {
	public static readonly HOME = {
		path: "/",
	};

	public static readonly NOT_AUTHORIZED = {
		path: "/not-authorized",
	};

	public static readonly LOGIN = {
		path: "/login",
	};

	public static readonly GENERATE_IMAGE = {
		path: "/generate-image",
		nameKey: "generateImages",
		showInPageDropdown: true,
	};

	public static readonly IMAGE_EDITOR = {
		path: "/image-editor",
		nameKey: "aiImageEditor",
		showInPageDropdown: true,
	};

	public static readonly MY_ASSETS = {
		path: "/my-assets",
		nameKey: "myAssets",
		showInPageDropdown: true,
	};

	public static readonly CAMPAIGN = {
		path: "/campaign",
		nameKey: "campaign",
		showInPageDropdown: true,
	};

	public static readonly USER_INFO = {
		path: "/user-info",
	};
}
