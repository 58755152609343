import { Box } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useLocation } from "react-router-dom";
import BriaIframe from "../../components/common/BriaIframe/BriaIframe";
import { PagesEnum } from "../../constants/IframeConstants";
import { getPublicisSelectedOrganization } from "../../utils";
import styles from "./Campaign.module.scss";

const Campaign = () => {
	const location = useLocation();
	return (
		<Box className={styles.container}>
			<BriaIframe
				iframeId={getPublicisSelectedOrganization().iframes.CAMPAIGN_IFRAME_ID}
				target={PagesEnum.Campaign}
				vhash={location.state?.vhash}
				imageUrl={location.state?.imageUrl}
			/>
		</Box>
	);
};

export default observer(Campaign);
