import ErrorIcon from "@mui/icons-material/Error";
import { Box, CircularProgress, Typography } from "@mui/material";
import { SAMLAuthProvider } from "firebase/auth";
import { Form, Formik, FormikErrors } from "formik";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import BriaButton, { BUTTONTYPES } from "../../../components/common/BriaButton/BriaButton.tsx";
import BriaInput from "../../../components/common/BriaInput/BriaInput.tsx";
import { ORG_CONSTANTS, PUBLICIS_LOREAL_ORG_ID, PUBLICIS_ORG_ID } from "../../../constants/OrgConstants.ts";
import RouterConstants from "../../../constants/RouterConstants.ts";
import { useAuthService } from "../../../hooks/useAuthService.tsx";
import { isLoreal } from "../../../utils";
import { isValidEmail } from "../../../utils/validators.ts";
import classes from "./Login.module.scss";

interface FormValues {
	email: string;
	password: string;
}

const Login = () => {
	const initialValues = {
		email: "",
		password: "",
	};
	const navigate = useNavigate();
	const location = useLocation();
	const { t } = useTranslation();
	const { loginWithEmail, authenticateWithPopup, loading, errorMessage, setErrorMessage } = useAuthService();
	const queryParams = new URLSearchParams(location.search);
	const continueUrl = queryParams.get("continueUrl") || undefined;
	const state = location.state;

	const signInUsingProvider = async (authProvider: any) => {
		const userInfo = await authenticateWithPopup(authProvider);
		if (!userInfo) {
			navigate(RouterConstants.USER_INFO.path);
		} else {
			navigate(RouterConstants.HOME.path);
		}
	};

	const handleSubmit = async (values: FormValues) => {
		await loginWithEmail(values.email, values.password, continueUrl, state);
	};

	return (
		<Box className={classes.content}>
			<Typography className={classes.mainTitle}>
				{t("welcomeToPublicisAIHub", {
					title: t(
						isLoreal()
							? ORG_CONSTANTS[PUBLICIS_LOREAL_ORG_ID].hubTitle
							: ORG_CONSTANTS[PUBLICIS_ORG_ID].hubTitle,
					),
				})}
			</Typography>
			<Typography className={classes.subTitle}>{t("welcomeToPublicisAIHubSubTitle")}</Typography>
			<Box className={classes.signInWithContainer}>
				{isLoreal() ? (
					<Formik
						initialValues={initialValues}
						validateOnMount={false}
						validateOnChange={false}
						validateOnBlur={false}
						validate={(values) => {
							const errors: FormikErrors<FormValues> = {};
							if (!values.email) {
								errors.email = t("emailIsRequired");
							} else if (!isValidEmail(values.email)) {
								errors.email = t("invalidEmail");
							}

							if (!values.password) {
								errors.password = t("passwordIsRequired");
							}
							if (Object.keys(errors).length !== 0) {
								setErrorMessage("");
							}

							return errors;
						}}
						onSubmit={handleSubmit}
					>
						{({ errors, touched, handleChange, handleBlur }) => (
							<Form className={classes.form} noValidate>
								<Box className={classes.formElements}>
									<BriaInput
										id="email"
										label={t("email")}
										onChange={handleChange}
										error={(Boolean(errors.email) && touched.email) as boolean}
										onBlur={handleBlur}
										helperText={touched.email ? errors.email : ""}
										className={classes.textField}
									/>
									<BriaInput
										id="password"
										label={t("password")}
										type="password"
										autoComplete="current-password"
										onChange={handleChange}
										onBlur={handleBlur}
										error={(Boolean(errors.password) && touched.password) as boolean}
										helperText={touched.password ? errors.password : ""}
										className={classes.textField}
									/>

									{errorMessage && (
										<Box className={classes.errorWrapper}>
											<Typography className={classes.error} color="secondary">
												<ErrorIcon className={classes.errorIcon} />
												{t(`${errorMessage}`.replace(/\s/g, "").replace(":", ""))}
											</Typography>
										</Box>
									)}

									<BriaButton
										buttonType={BUTTONTYPES.PRIMARY}
										type="submit"
										variant="contained"
										color="secondary"
										fullWidth={true}
									>
										{t("logIn")}
										<Box className={classes.circleLoader}>
											{loading && <CircularProgress color="inherit" size={25} />}
										</Box>
									</BriaButton>
								</Box>
							</Form>
						)}
					</Formik>
				) : (
					<BriaButton
						buttonType={BUTTONTYPES.OUTLINED}
						variant="outlined"
						startIcon={<img className={classes.icon} src={ORG_CONSTANTS[PUBLICIS_ORG_ID].logo} />}
						onClick={async () => {
							signInUsingProvider(new SAMLAuthProvider("saml.publicis"));
						}}
					>
						{t("signInWithPublicis")}
					</BriaButton>
				)}
			</Box>
		</Box>
	);
};

export default Login;
