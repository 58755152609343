import ErrorIcon from "@mui/icons-material/Error";
import { Box, CircularProgress, Typography } from "@mui/material";
import { Form, Formik } from "formik";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import BriaButton, { BUTTONTYPES } from "../../../components/common/BriaButton/BriaButton.tsx";
import BriaInput from "../../../components/common/BriaInput/BriaInput.tsx";
import RouterConstants from "../../../constants/RouterConstants.ts";
import { updateUserInfo } from "../../../helpers/firebase.ts";
import { useAppStore } from "../../../hooks/useStores.tsx";
import classes from "./UserInfo.module.scss";

const UserInfo = () => {
	const initialValues = {
		username: "",
	};
	const { authStore } = useAppStore();
	const navigate = useNavigate();
	const { t } = useTranslation();
	const [errorMessage, _setErrorMessage] = useState<boolean | string>(false);
	const [loading, setIsLoading] = useState<boolean>(false);

	const handleSubmit = async (values: any) => {
		if (authStore.user) {
			setIsLoading(true);
			const userObject = await updateUserInfo(authStore.user, values.username, "Publicis");
			if (userObject) {
				await authStore.setUser(userObject);
			}
			setIsLoading(false);
			navigate(RouterConstants.HOME.path);
		}
	};

	return (
		<Box className={classes.content}>
			<Typography className={classes.mainTitle}>{t("whatsYourName")}</Typography>
			<Formik
				initialValues={initialValues}
				validateOnMount={false}
				validateOnChange={false}
				validateOnBlur={false}
				validationSchema={Yup.object().shape({
					username: Yup.string().required(t("fullNameIsRequired")),
				})}
				onSubmit={handleSubmit}
			>
				{({ values, errors, touched, handleChange, handleBlur }) => (
					<Form className={classes.form} noValidate>
						<Box className={classes.formElements}>
							<BriaInput
								id="username"
								label={t("fullName")}
								onChange={handleChange}
								onBlur={handleBlur}
								value={values.username}
								error={(Boolean(errors.username) && touched.username) as boolean}
								helperText={touched.username ? errors.username : ""}
								className={classes.textField}
							/>

							{errorMessage && (
								<Typography className={classes.error} color="secondary">
									<ErrorIcon className={classes.errorIcon} />
									{t(`${errorMessage}`)}
								</Typography>
							)}
							<BriaButton
								buttonType={BUTTONTYPES.PRIMARY}
								type="submit"
								variant="contained"
								color="secondary"
								fullWidth={true}
							>
								{t("getStarted")}
								<Box className={classes.circleLoader}>
									{loading && <CircularProgress color="inherit" size={25} />}
								</Box>
							</BriaButton>
						</Box>
					</Form>
				)}
			</Formik>
		</Box>
	);
};

export default observer(UserInfo);
