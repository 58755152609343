import { Box, Container } from "@mui/material";
import styles from "./AuthLayout.module.scss";

import clsx from "clsx";
import { observer } from "mobx-react-lite";
import { Navigate, Outlet } from "react-router-dom";
import { LoadingPlaceholder } from "../../components/common/LoadingPlaceholder/LoadingPlaceholder.tsx";
import { ORG_CONSTANTS, PUBLICIS_LOREAL_ORG_ID, PUBLICIS_ORG_ID } from "../../constants/OrgConstants.ts";
import RouterConstants from "../../constants/RouterConstants.ts";
import { useAppStore } from "../../hooks/useStores.tsx";
import { isLoreal } from "../../utils";

interface LayoutProps {
	ignoreRedirect?: boolean;
}

const AuthLayout = ({ ignoreRedirect = false }: LayoutProps) => {
	const { authStore } = useAppStore();

	const getContent = () => {
		return (
			<Container component="main" maxWidth={false} className={clsx(styles.background, styles.container)}>
				<Box className={styles.box}>
					<img
						src={
							isLoreal()
								? ORG_CONSTANTS[PUBLICIS_LOREAL_ORG_ID].logo
								: ORG_CONSTANTS[PUBLICIS_ORG_ID].logo
						}
						className={clsx(styles.logo, { [styles.loreal]: isLoreal() })}
					/>
				</Box>
				<Box className={styles.credentials_content}>
					<Outlet />
				</Box>
			</Container>
		);
	};

	return (
		<LoadingPlaceholder className={styles.loaderStyle} isLoading={authStore.isLoading}>
			{authStore.isLoggedIn && !ignoreRedirect ? <Navigate to={RouterConstants.HOME.path} /> : getContent()}
		</LoadingPlaceholder>
	);
};

export default observer(AuthLayout);
