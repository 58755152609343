import { Box } from "@mui/material";
import { observer } from "mobx-react-lite";
import BriaIframe from "../../components/common/BriaIframe/BriaIframe";
import { PagesEnum } from "../../constants/IframeConstants";
import { getPublicisSelectedOrganization } from "../../utils";
import styles from "./GenerateImages.module.scss";

const GenerateImages = () => {
	return (
		<Box className={styles.container}>
			<BriaIframe
				iframeId={getPublicisSelectedOrganization().iframes.GENERATE_IFRAME_ID}
				target={PagesEnum.Gallery}
			/>
		</Box>
	);
};

export default observer(GenerateImages);
