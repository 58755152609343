import { onAuthStateChanged } from "firebase/auth";
import { makeAutoObservable, runInAction } from "mobx";
import { BriaAxios } from "../../config/axios";
import { firebaseAuth } from "../../config/firebase.ts";
import { DEFAULT_ORG, ORG_CONSTANTS } from "../../constants/OrgConstants.ts";
import { getUserWithUID } from "../../helpers/firebase.ts";
import {
	clearSelectedOrganization,
	getSelectedOrganization,
	setSelectedOrganization,
} from "../../helpers/localStorage.ts";
import { UserOrganization } from "../../models/organization.ts";
import User from "../../models/user.ts";

export interface IAuthStore {
	user: User | null;
	userOrganizations: UserOrganization[];
	isLoggedIn: boolean;
	isLoading: boolean;
	isError: boolean;

	logout(): Promise<void>;

	setUser(user: User | null): Promise<void>;

	loadOrganizations(): Promise<void>;

	setPostRegistrationConfigs(): Promise<void>;
}

export default class AuthStore implements IAuthStore {
	user: User | null = null;
	userOrganizations: UserOrganization[] = [];
	isLoggedIn = false;
	isError = false;
	isLoading = true;

	constructor() {
		makeAutoObservable(this);
		onAuthStateChanged(firebaseAuth, (user) => {
			if (user) {
				this.isLoading = true;
				this.isError = false;

				getUserWithUID(user.uid)
					.then((userObject) => {
						if (userObject) {
							this.setUser(userObject);
						} else {
							this.setUser(null);
						}
					})
					.catch((error) => {
						console.log(error);
					});
			} else {
				this.setUser(null);
			}
		});
	}

	logout = async (): Promise<void> => {
		try {
			this.isLoggedIn = false;
			await firebaseAuth.signOut();
			localStorage.clear();
			window.location.reload();
		} catch (e) {
			runInAction(() => {
				this.isLoading = false;
				this.isError = true;
			});
			return Promise.reject(e);
		}
	};

	setUser = async (user: User | null): Promise<void> => {
		try {
			this.user = user;
			if (user != null) {
				await this.loadOrganizations();
			}
			this.isLoggedIn = user !== null;
			this.isLoading = false;
			this.isError = false;
		} catch (e) {
			runInAction(() => {
				this.isLoading = false;
				this.isError = true;
			});
			return Promise.reject(e);
		}
	};

	loadOrganizations = async (): Promise<any> => {
		try {
			const response = await (await BriaAxios()).get("/organizations/");

			runInAction(() => {
				this.userOrganizations = response.data.filter((org: UserOrganization) =>
					Object.keys(ORG_CONSTANTS).includes(org.organization.uid),
				);

				let selectedOrg = getSelectedOrganization();
				if (!Object.keys(ORG_CONSTANTS).includes(selectedOrg?.organization.uid ?? "")) {
					selectedOrg = null;
				}

				if (this.userOrganizations.length > 0) {
					const defaultUserOrg = this.userOrganizations.find((org) => org.organization.uid === DEFAULT_ORG);
					if (!selectedOrg) {
						setSelectedOrganization(defaultUserOrg ?? this.userOrganizations[0]);
					} else {
						const foundOrg = this.userOrganizations.find(
							(org) => org.organization.uid === selectedOrg?.organization.uid,
						);
						if (!foundOrg) {
							setSelectedOrganization(defaultUserOrg ?? this.userOrganizations[0]);
						}
					}
				} else {
					clearSelectedOrganization();
				}
			});
		} catch (e) {
			this.isLoading = false;
			this.isError = true;
			return Promise.reject(e);
		}
	};

	setPostRegistrationConfigs = async (): Promise<void> => {
		try {
			await (await BriaAxios()).post("/users/set_post_registration_configs", {});
		} catch (e) {
			return Promise.reject(e);
		}
	};
}
