import { Avatar, Box, Menu, MenuItem, SelectChangeEvent, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import styles from "./Header.module.scss";

import BriaButton from "../../components/common/BriaButton/BriaButton";

import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import BriaDropDown from "../../components/common/BriaDropDown/BriaDropDown.tsx";
import OrganizationsDropdown from "../../components/common/OrganizationsDropdown/OrganizationsDropdown.tsx";
import RouterConstants from "../../constants/RouterConstants.ts";
import { useAppStore } from "../../hooks/useStores.tsx";
import { getCurrentRoute, getPublicisSelectedOrganization } from "../../utils";

type HeaderProps = {
	showPageDropdown?: boolean;
};

const Header = ({ showPageDropdown }: HeaderProps) => {
	const navigate = useNavigate();
	const history = useLocation();
	const { t } = useTranslation();
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const [selectedPage, setSelectedPage] = useState<string>(getCurrentRoute()?.path);

	const { authStore } = useAppStore();

	const handleMenuClose = () => {
		setAnchorEl(null);
	};

	useEffect(() => {
		setSelectedPage(history.pathname);
	}, [history.pathname]);

	return (
		<Box className={styles.headerContainer}>
			<Box className={styles.leftContainer}>
				<img
					className={styles.logo}
					src={getPublicisSelectedOrganization().logo}
					onClick={() => {
						navigate(RouterConstants.HOME.path);
					}}
				/>
				<Box className={styles.dropdownContainer}>
					{authStore.userOrganizations.length > 1 && <OrganizationsDropdown />}
					{showPageDropdown && (
						<BriaDropDown
							className={styles.pageDropdown}
							items={Object.entries(RouterConstants)
								.filter((page) => page[1].showInPageDropdown)
								.map((page) => {
									return {
										key: t(page[1].nameKey),
										value: page[1].path,
									};
								})}
							value={selectedPage}
							onChange={(event: SelectChangeEvent<string>) => {
								setSelectedPage(event.target.value);
								navigate(event.target.value);
							}}
							border={"none"}
						/>
					)}
				</Box>
			</Box>
			<Box className={styles.buttonsContainer}>
				<Box className={styles.userIconConainter}>
					<BriaButton
						onClick={(event) => {
							setAnchorEl(event.currentTarget);
						}}
						aria-label="account of current user"
					>
						<Avatar
							src={
								authStore.user?.profilePicture ??
								"https://img.freepik.com/free-vector/businessman-character-avatar-isolated_24877-60111.jpg?w=740&t=st=1697705199~exp=1697705799~hmac=0ce79d0ad1618e211eef0e882f46b832d072a6b9aa1158fbe4dfd8e48407af8f"
							}
							className={styles.avatarStyle}
						/>
						{/*<b className={styles.userName}>{authStore.user?.userName}</b>*/}
						{/*<KeyboardArrowDown className={styles.arrowStyle} />*/}
					</BriaButton>
				</Box>
				<Menu
					anchorEl={anchorEl}
					open={Boolean(anchorEl)}
					onClose={handleMenuClose}
					anchorOrigin={{
						vertical: "bottom",
						horizontal: "center",
					}}
					PaperProps={{
						style: {
							width: 150,
						},
					}}
				>
					{/* <MenuItem className={styles.menuItem} disabled>
						<Typography variant="inherit" className={styles.menuItemHeader}>
							{t("Profile")}
						</Typography>
					</MenuItem> */}
					<MenuItem
						onClick={() => {
							authStore.logout().finally(() => {
								handleMenuClose();
								navigate(RouterConstants.LOGIN.path);
							});
						}}
					>
						<Typography variant="inherit">{t("logout")}</Typography>
					</MenuItem>
				</Menu>
			</Box>
		</Box>
	);
};

export default observer(Header);
